<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        v-if="users.selected"
        class="border border-blue-300 flex gap-4 items-center p-4 rounded"
      >
        <span
          class="bg-blue-500 grid h-10 rounded-full place-items-center text-white w-10"
        >
          <ion-icon name="person" />
        </span>
        <div class="opacity-75">
          <div class="font-semibold">{{ users.selected.name }} {{ users.selected.last_name }}</div>
          <div class="text-sm">{{ users.selected.email }}</div>
        </div>
        <button class="btn btn-gray btn-sm ml-auto" @click="clear">
          Choose someone else
        </button>
      </div>
      <div v-else class="border border-blue-300 rounded">
        <datatable
          :url="users.url"
          :ajax="true"
          :ajaxPagination="true"
          :columns="users.columns"
          :filters="users.filters"
          :query="users.query"
          :fillable="false"
          :dropdown="false"
          :on-click="selectUser"
          sort="created_at"
          order="desc"
          ref="table"
        >
          <template #header>
            <div class="pt-4">
              <search-form
                placeholder="Search users names, emails, phone numbers, bvn, etc."
                class="mb-5"
                v-model="users.query"
                @submit="reloadTable"
              />
            </div>
          </template>
        </datatable>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UserSelection',
  data() {
    return {
      users: this.$options.resource([], {
        selected: null,
        query: '',
        url: `${this.$baseurl}/admin/personal/users`,
        columns: [
          {
            name: 'id',
            th: 'User ID',
          },
          {
            name: 'name',
            th: 'Name',
            render: (user) => user.name + ' ' + user.last_name,
          },
          {
            name: 'email',
            th: 'Email Address',
          },
          {
            name: 'phone_no',
            th: 'Phone Number',
          },
        ],
      }),
    };
  },
  computed: {},
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    clear() {
      this.users.selected = null;
      this.$emit('select', null);
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    selectUser(user) {
      this.users.selected = user;
      this.$emit('select', user);
    },
  },
};
</script>
